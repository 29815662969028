.dataTabs button {
  color: white;
  margin-right: 10px;
  border-radius: 0px 0px 8px 8px;
}

.dataTabs button.Mui-selected {
  color: white;
  background-color: #d4af37;
}

.MuiTabs-indicator {
  display: none !important;
}

.tabItem {
  padding-bottom: 0px !important;
  color: black;
  min-height: 286px;
  position: relative !important;
}

.slider {
  position: relative;
}

.sliderLinkContent{
  max-height: 250px;
  overflow: auto; 
  position: relative;

  scrollbar-width: thin; 
  scrollbar-color: #999 transparent; 

 
  &::-webkit-scrollbar {
    width: 8px; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: #391D69; 
    border-radius: 10px; 
    margin: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; 
  }
}

.slick-list {
  height: 100% !important;
  z-index: 0;
}

.slick-track {
  display: flex !important;
  gap: 10px !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  position: relative !important;
  height: 230px !important;

  /* width: 300px; */
}

.sliderImg {
  position: relative !important;
  height: inherit !important;
  width: 100%;
  object-fit: cover !important;
}

.sliderDoc {
  position: relative;
  height: inherit;
  width: 100%;
  background-color: white;
  color: rgb(50, 50, 50);
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
}

.sliderContent {
  position: relative;
  max-height: 250px; 
  background-color: white;
  color: #391D69;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: auto; 
  margin-right: 5px;
}

.spacer {
  flex-grow: 1; 
}

.sliderDoc img {
  width: 50px;
  padding: 30px;
}

.docName{
  text-transform: uppercase;
  word-break: break-word;
}

.docInfo {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.docInfo span:first-child {
  text-transform: capitalize;
  word-break: break-word;
}
.docInfo span:last-child {
  text-transform: capitalize;
  font-weight: lighter;
}

.seeMoreIcon {
  padding: 30px;
  color: rgb(100, 100, 100);
  cursor: pointer;
}
.downloadIcon {
  padding: 10px;
  color: #391D69;
  cursor: pointer;
}
.downloadIcon:hover{
  color: #d4af37;
  cursor: pointer; 
}


.linkIcon {
  padding: 10px;
  cursor: pointer;
}
.linkIcon:hover{
  /*fill: #d4af37; */
  /* filter: sepia(97%) saturate(6984%) hue-rotate(324deg) brightness(86%) contrast(92%); */
  filter: invert(58%) sepia(92%) saturate(324%) hue-rotate(8deg) brightness(100%) contrast(90%);
  cursor: pointer; 
}

.sliderArrows {
  position: relative;
  text-align: center;
  margin-top: 10px;
  z-index: 10;
}

.sliderArrows button {
  border-radius: 200px;
  line-height: 45px;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border: none;
  color: white;
  background-color: rgb(0, 134, 142);
  text-align: center;
  margin: 5px;
  padding: 0px;
}

.sliderArrows button > svg {
  transform: scale(0.6) !important;
}

.tabEmptyState {
  display: flex;
  position: absolute;
  height: 100% !important;
  width: 100%;
  flex-direction: column;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  text-align: center;
}

.tabEmptyStateContent img {
  width: 120px;
}

.tabEmptyState p {
  margin: 0;
  margin-top: 10px;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  color: #391D69 !important;
}
