.leaflet-container {
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 0;
}

.content {
  position: relative;
  width: 100%;
  height: 100vh;
}

.marker-cluster div {
  background-color: rgba(212, 175, 55, 0.85);
  transition: all ease-in-out 0.3s;
}

.marker-cluster {
  background-color: rgba(212, 175, 55, 0.6);
}

.marker-cluster span {
  color: white;
  opacity: 1;
  font-size: 15px;
  font-weight: bold !important;
}

.marker-cluster div:hover {
  transform: scale(1.3);
}
