.swiper {
  padding-bottom: 30px !important;
}

.swiper-slide {
  position: relative !important;
  height: auto !important;
  align-self: stretch;
}

.swiper-slide img {
  height: 100% !important;
  object-fit: cover !important;
}

.swiper-pagination-bullets {
  justify-content: center !important;
}

.swiper-pagination-bullet-active {
  background-color: rgb(57, 29, 105) !important;
}

.mobileDocument {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  color: rgb(80, 80, 80);
  width: 100%;
}

.mobileDocument img {
  width: 40px;
  margin-right: 20px;
}

.mobileDocInfo {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  color: rgb(80, 80, 80);
  font-size: 14px;
}

.mobileDocInfo span:nth-child(1) {
  font-weight: bold;
}

.mobileDocInfo span:nth-child(2) {
  text-transform: capitalize;
}


/**/
.sliderLinkContent{
  width: auto;
  position: relative;
}

.sliderContent {
  position: relative;
  max-height: 250px; 
  background-color: white;
  color: #391D69;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-right: 5px;
  justify-content: space-between;
}


.docName{
  text-transform: uppercase;
}

.docInfo {
  display: flex;
  flex-direction: row;
  margin-left: 33px;
}

.docInfo span:first-child {
  text-transform: capitalize;
  word-break: break-word;
}
.docInfo span:last-child {
  text-transform: capitalize;
  font-weight: lighter;
}

.linkIconContainer {
  position: fixed;
  right: 0;
  cursor: pointer;
}

.linkIcon {
  padding: 10px;
  cursor: pointer;
  margin-left: auto;
}
.linkIcon:hover{
  filter: invert(58%) sepia(92%) saturate(324%) hue-rotate(8deg) brightness(100%) contrast(90%);
  cursor: pointer; 
}

.downloadIcon {
  padding: 10px;
  color: #391D69;
  cursor: pointer;
}
.downloadIcon:hover{
  color: #d4af37;
  cursor: pointer; 
}
