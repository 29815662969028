.infoModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
}

.movieModalContent {
  position: relative;
}
.header{
  position: relative;
  padding: 30px 30px;
}
.modalTabs{
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  height: 375px;
}
.filmTabs{
  height: 315px;
}

.movieModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: -1;
}

.movieModalContent .header {
  color: white;
}

.movieModalContent .header h1 {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 5px;
  margin: 0;
}

.movieModalContent .header h2 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
}

.movieModalContent .header ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  font-size: 15px;
}

.movieModalContent .header li {
  margin-right: 20px;
}

.movieModalContent .header p {
  font-size: 15px;
  margin-top: 10px;
  text-align: justify;
}

.movieTag {
  display: inline-block;
  height: 24px;
  line-height: 25px;
  position: relative;
  margin: 0 25px 0px 0px;
  padding: 0 12px 0 10px;
  background: #FFFFFF !important;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  color: #000000;
  font-size: 12px;
}

.movieTag:last-child {
  margin-right: 0;
}

.movieTag:after {
  content: "";
  position: absolute;
  top: 0;
  right: -11px;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #FFFFFF !important;
  border-style: solid;
  border-width: 12px 0px 12px 12px;
}

.movieTag:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 1px;
  float: right;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.tag-Film:before {
  background: #D4AF37; 
}

.tag-Experiences:before {
  background: #391D69; 
}

.tag-AbouttheFilm:before {
  background: #009999; 
}

.movieModalBtns {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 10px !important;
  padding: 5px 0 0 0;
  height: fit-content;
  width: fit-content;
}

.modalBtn {
  position: relative;
  background: #D4AF37;
  border: none;
  color: white;
  width: 130px;
  height: 48px;
  margin: 20px 10px 20px;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
}

.modalBtn:hover {
  background-color: #E7BF3D;
}

.infoClose {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px;
  cursor: pointer;
}

.modalLoadingContainer {
  width: 100%;
  height: 575px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 1440px) {
  .modalTabs{
    height: 355px;
  }
  .filmTabs{
    height: 315px;
  }
}

@media only screen and (max-width: 1280px) {
  .modalTabs{
    height: 335px;
  }
  .filmTabs{
    height: 300px;
  }
}
