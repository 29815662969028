.mobileModal {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  overflow-y: scroll;
}

.mobileInfoClose {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 20px;
  fill: white !important;
}

.mobileHeader img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.mobileHeader {
  position: relative;
}

.mobileOverlay {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.headerText {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 60px;
  left: 0;
  color: white;
  padding: 0px 20px;
}

.headerText label {
  font-weight: bold;
  font-size: 16px;
}

.headerText label:first-child {
  padding-bottom: 5px;
}

.headerText .modalTags {
  margin-top: 3px;
}

.headerText .modalTags .movieTag {
  background-color: #ffffff !important;
  margin-top: 5px;
}

.headerText .modalTags .movieTag::after {
  border-color: transparent transparent transparent #ffffff !important;
}

.tag-Film:before {
  background: #D4AF37; 
}

.tag-Experiences:before {
  background: #391D69; 
}

.tag-AbouttheFilm:before {
  background: #009999; 
}


.headerBtns {
  position: absolute;
  bottom: -20px;
  padding: 0px 20px;
  display: flex;
  gap: 30px;
}

.mobileBtn {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  background: #d4af37;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.headerText label img{
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

.watchText {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
.headerBtns a {
  text-decoration: none;
}

.headerBtns a .mobileBtn svg {
  transform: scale(1.5);
}

.mobileBtn svg {
  transform: scale(1.2);
}

.modalBody {
  margin-top: 50px;
  padding: 0 20px;
  text-align: justify;
  color: rgb(70, 70, 70);
}

.mobileGallery {
  position: relative;
  padding: 0 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.galleryCollapse {
  border-radius: 0px;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.collapseContent {
  padding: 10px 40px 10px 40px;
}

.collapseContent div {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.collapseContent div label {
  color:  rgb(57, 29, 105);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.divsLabels{
  display: flex;
  align-items: center;
}

.collapse {
  padding-top: 10px;
}