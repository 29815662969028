.translationsSection {
  position: absolute;
  right: 35px;
  top: 35px;
  z-index: 2;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.lang {
  text-transform: capitalize;
}

.dropdown:checked + label,
.dropdown:not(:checked) + label {
  position: relative;
  height: 35px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 150px;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 15px;
  border: none;
  background-color: white;
  cursor: pointer;
  color: #00868e;
  box-shadow: 0 12px 35px 0 rgba(80, 80, 80, 0.15);
}

.dropdown:not(:checked) + label .arrow {
  transition: transform 200ms linear;
}

.dropdown:checked + label .arrow {
  transform: rotate(180deg);
  transition: transform 200ms linear;
}

.dropMenu {
  position: absolute;
  background-color: white;
  top: 45px;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}

.dropdown:checked ~ .dropMenu {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.dropMenu span {
  position: relative;
  color: #00868e;
  transition: all 200ms linear;
  border-radius: 4px;
  padding: 10px 0;
  padding-left: 20px;
  padding-right: 15px;
  display: flex;
  cursor: pointer;
}

.dropMenu span:hover {
  color: white;
  background-color: rgba(0, 134, 142, 0.6);
}

/* width */
.dropMenu::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.dropMenu::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.dropMenu::-webkit-scrollbar-thumb {
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
}

/* RESPONSIVENESS */
@media only screen and (max-width: 600px) {
  .translationsSection {
    visibility: hidden;
  }
}
