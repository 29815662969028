.movieCard {
  position: relative;
  height: 150px;
  width: 240px;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
}

.movieCard:hover {
  transform: scale(1.01);
}

.movieCard img {
  width: 100%;
  height: 100%;
  border-radius: 7px !important;
  object-fit: cover;
}

.cardOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 7px !important;
}

.cardOverlay h1 {
  position: absolute;
  bottom: 5px;
  margin: 0 auto 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 15px;
  padding: 10px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: fit-content;
  font-weight: 400;
}

.iconsType{
  width: 25px;
  margin-left: 8px;
}

.cardLoadingContainer {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 480px) {
  .movieCard {
    width: 100%;
  }
}
