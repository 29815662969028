.locationModal {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
}

.locationModalHeader {
  background-color: #00868e;
  color: white;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  cursor: pointer;
}

.locationModalHeader h2 {
  margin: 0;
  font-size: 18px;
}

.locationModalHeader h2 > span {
  font-size: 15px;
  font-weight: 400;
}

.locationModalBody {
  position: relative;
  flex-grow: 1;
  margin: 30px 30px 30px 30px;
  overflow-y: scroll;
}

.locationModalBody::-webkit-scrollbar {
  width: 10px;
}

.locationModalBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(130, 130, 130, 0.7);
}

.movieGridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .locationModal {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
