#menuSwitcher {
  display: none;
}

.sideSwitcher {
  position: absolute;
  width: 40px;
  height: 145px;
  background-color: #00868e;
  right: 0px;
  top: 0;
  transform: translateX(100%);
  line-height: 155px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  #menuSwitcher:checked ~ .filters .sideSwitcher img {
    transition: 0.5s all ease-in-out;
  }

  #menuSwitcher:not(:checked) ~ .filters .sideSwitcher img {
    transform: rotate(180deg);
    transition: 0.5s all ease-in-out;
  }

  #menuSwitcher:checked ~ .filters {
    transform: translateX(-100%);
    transition: 0.5s all ease-in-out;
  }

  #menuSwitcher:not(:checked) ~ .filters {
    transition: 0.5s all ease-in-out;
  }

  .filters {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    /* top: 35px; */
    top: 5px;
    background: #fff;
    width: 350px;
    height: 70%;
    /* height: 440px; */
    /* height: 500px; */
    border-radius: 0 0 10px 0;
  }
}

.filtersType {
  display: block;
  position: absolute;
  right: 65px;
  bottom: 65px;
  z-index: 2;
}

.tag-Film {
  background: #d4af37;
}

.tag-Experiences {
  background: #391d69;
}

.tag-AbouttheFilm {
  background: #009999;
}
.formCtrl {
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconsType {
  width: 30px;
  margin-left: 8px;
}

.sideSwitcher img {
  width: 20px;
}

.filters h1 {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 5px;
}

.searchSection {
  background-color: #00868e;
  padding: 20px;
  min-height: 145px;
}

.searchSection h1 {
  color: white;
}

.autocomplete input[type="text"] {
  width: 95%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  height: 40px;
  padding: 0px 10px;
  color: white;
  font-size: 15px;
}

.autocomplete input[type="text"]:focus {
  outline: none !important;
}

.autocomplete input[type="text"]::placeholder {
  color: white;
}

.autocompleteOption {
  display: flex;
  justify-content: center;
}

.autocompleteOption img {
  width: 20px !important;
  height: 20px;
  margin-right: 15px;
}

.tagsSection {
  margin-top: 20px;
}

.tagsGrid {
  margin-top: 5px !important;
}

.tagsGrid button {
  border: 1px solid #00adb7;
  background: transparent;
  padding: 5px 15px;
  height: 100%;
  width: 100%;
  font-size: 12px;
  margin: 0 5px 10px 0;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  word-break: break-all;
  transition: 0.3s all ease-in-out;
  font-family: "Yantramanav", sans-serif;
}

.tagsGrid button:hover {
  background: #00adb7;
  color: white;
}

.appliedFiltersTitle {
  padding: 15px 20px 0 20px;
  margin: 0 !important;
}

.appliedFilters {
  padding: 5px 20px 20px 20px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.appliedGrid {
  margin-top: 5px !important;
}

.appliedBtn {
  display: flex;
  background-color: #00868e;
  border: 1px solid #00868e;
  color: white;
  max-width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
}

.appliedBtn:hover {
  cursor: pointer;
}

.appliedBtn svg {
  transform: scale(0.8);
  transition: 0.3s all ease-in-out !important;
}

.appliedBtn svg:hover + .appliedSpan span {
  visibility: visible;
  width: 100%;
  color: rgba(255, 255, 255, 1);
}

.appliedSpan {
  position: relative;
  height: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: white;
  color: rgb(50, 50, 50);
  padding: 0px 10px;
}

.appliedSpan span {
  position: absolute;
  text-transform: capitalize;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  visibility: hidden;
  text-align: center;
  line-height: 36px;
  background-color: #00868e;
  color: rgba(255, 255, 255, 0);
  transition: 0.3s all ease-in-out;
}

.filtersEmptyState {
  text-align: center;
  padding: 0px;
}

.filtersEmptyState div {
  position: absolute;
  /* top: 50%; */
  top: 60%;
  left: 0;
  right: 0;
}

.filtersEmptyState img {
  /* width: 225px; */
  width: 44px;
  margin-top: 40px;
}

.filtersEmptyState p {
  color: rgba(65, 105, 225, 0.5);
  margin-top: 0px;
}

.clearBtn {
  padding: 20px;
}

.clearBtn button {
  background: #00868e;
  color: white;
  height: 35px;
  width: 100%;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.clearBtn button:hover {
  background: #00adb7;
}

/* RESPONSIVENESS */

.smallFilterBtn {
  background: white;
  position: absolute;
  top: 20px;
  left: 35px;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  z-index: 1;
  transition: 0.8s all ease-in-out;
}

.smallFilterBtn div {
  background-color: #00868e;
  width: 70%;
  height: 3px;
  border-radius: 100px;
  transition: 0.5s all ease-in-out;
}

#menuSwitcher:checked ~ .smallFilterBtn {
  box-shadow: none;
  left: 85%;
  background-color: #00868e;
}

#menuSwitcher:checked ~ .smallFilterBtn div:nth-child(1) {
  transform: rotate(45deg) translate(4.2px, 4.2px);
  background: white;
}

#menuSwitcher:checked ~ .smallFilterBtn div:nth-child(3) {
  transform: rotate(-45deg) translate(5.5px, -5px);
  background: white;
}

#menuSwitcher:checked ~ .smallFilterBtn div:nth-child(2) {
  transform: translateX(-20px);
  background-color: transparent;
  transition: 0.1s all ease-in-out;
}

@media only screen and (max-width: 480px) {
  #menuSwitcher:not(:checked) ~ .filters {
    transform: translateX(-100%);
  }

  #menuSwitcher:checked ~ .filters {
    transform: translateX(0%);
  }

  .filters {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 0px;
    background: #fff;
    width: 100%;
    /* height: 100%; */
    height: 70%;
    border-radius: 0 0 10px 0;
    z-index: 0;
    transition: 0.8s all ease-in-out;
  }

  .filtersType {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    width: 220px;
    left: 18px;
    border-radius: 10px 10px 10px 10px;
  }

  .acComponent {
    position: relative;
    width: 100% !important;
  }

  .autocomplete input[type="text"] {
    position: inherit;
    width: 94%;
  }
}
